$(window).scroll(function() {
  let header = $('.header');
  if($(this).scrollTop()>0){
    $(header).addClass('js-sticky');
  } else {
    $(header).removeClass('js-sticky');
  }
});

let langCurrent = $('.lang-sel__current');
let langToggle = $('.lang-sel__toggle');

$(langCurrent).click(function(){
  $(langToggle).toggleClass('js-show');
  $(langCurrent).toggleClass('js-show');
});

let menuToggle = $('.m-toggle');
let menuHamb = $('.m-hamb');

$(menuToggle).click(function(){
  $(this).toggleClass('js-open');
  $(menuHamb).toggleClass('js-open');
});

let footerToggle = $('.z-toggle');
let footerHamb = $('.zone-menu');

$(footerToggle).click(function(){
  $(this).toggleClass('js-open');
  $(footerHamb).toggleClass('js-open'); 
});